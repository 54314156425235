export default {
  CUSTOMER_TYPE: {
    AIRPORT: 'airport',
    AIRPLANE: 'airplane',
  },

  ABILITY: {
    DEFAULT: 'default',
    USER_MANAGEMENT: 'user_management',
    CUSTOMER_MANAGEMENT: 'customer_management',
    BIGQUERY_MANAGEMENT: 'bigquery_table_management',
    BIGQUERY_SELECTOR: 'bigquery_table_selection',
    LOG_VERSION_MANAGEMENT: 'logs_version_management',
    ANALYTICS: 'analytics',
    CUSTOMER: 'customer',
    INTERN: 'intern',
    CONFIGURATOR: 'configurator',
    DEVELOPER: 'developer',
    EXPORT: 'export_ui',
    EXPORT_SEARCH: 'export_search_data',
    DASHBOARD_MANAGEMENT: 'dashboard_management',
    VIDEO_ASSETS: 'video_assets',
    HIDDEN_VIEW: 'hidden_view',
  },
  DATA_LISTS: {
    DASHBOARD_ELEMENTS: {
      type: 'dashboard_elements',
      path: 'dashboard/getCompositeCustomerData',
      method: 'post',
      withOverlay: true,
    },
    DASHBOARD_ELEMENTS_LAYOUTS: {
      type: 'dashboard_elements_layouts',
      path: 'dashboard/getDashboardConstructionElementsLayouts',
      method: 'get',
      withOverlay: true,
    },
    DASHBOARD_ELEMENTS_METRICS: {
      type: 'dashboard_elements_metrics',
      path: 'dashboard/getDashboardConstructionElementsMetrics',
      method: 'get',
      withOverlay: true,
    },
    DASHBOARD_ELEMENTS_CLASSES: {
      type: 'dashboard_elements_classes',
      path: 'dashboard/getDashboardConstructionElementsClasses',
      method: 'get',
      withOverlay: true,
    },
    DASHBOARD_ELEMENTS_STRUCTURE: {
      type: 'dashboard_elements_structure',
      path: 'dashboard/getDashboardConstructionElementsStructure',
      method: 'get',
      withOverlay: true,
    },
  },
};
